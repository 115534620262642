<template>
  <div class="error-wrap">
    <UseMobile v-if="!isMobile" />
    <div class="error" v-else>
      <p>
        <span class="title">
          Ej no!<br />
          Potrzebujemy dostępu do&nbsp;Twojego aparatu!<br /><br />
        </span>
        <span>Jeżeli nadal nie działa, spróbuj w&nbsp;innej przeglądarce.</span>
      </p>

      <a href="/" class="link title">
        <img src="arrow-down.svg" alt="" class="arrow" />Wróć
      </a>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import UseMobile from '@/components/UseMobile.vue'
import { isMobile } from '@/assets/ts/utils'

export default defineComponent({
  components: { UseMobile },
  setup() {
    return { isMobile }
  }
})
</script>

<style lang="scss" scoped>
.error-wrap {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.error {
  position: absolute;
  top: 100px;
  bottom: 63px;
  left: 20px;
  right: 20px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.title {
  text-transform: uppercase;
  font-weight: $regular;
  font-size: 30px;
  line-height: 32px;
  letter-spacing: -0.3px;
}

.link {
  color: $white;
  text-align: left;
  text-decoration: underline;
}

.arrow {
  transform: rotate(90deg);
  margin-right: 18px;
}
</style>
