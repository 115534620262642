
import { defineComponent } from 'vue'

export default defineComponent({
  setup(_, ctx) {
    function enableCamera() {
      ctx.emit('enableCamera')
    }

    return { enableCamera }
  }
})
