<template>
  <canvas ref="canvasRef" class="canvas" />
  <transition name="fade">
    <Loader v-if="!videosLoaded" />
  </transition>
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive, ref } from 'vue'
import { useRouter } from 'vue-router'
import { useLoader } from '@/composite/videoLoading'
import Loader from '@/components/Loader.vue'

interface VideoInfo {
  copyVideo: boolean
  width: number
  height: number
  video: HTMLVideoElement
}

function setupCamera() {
  const router = useRouter()

  const videoInfo = reactive<VideoInfo>({
    copyVideo: false,
    width: 1,
    height: 1,
    video: document.createElement('video')
  })

  videoInfo.video.playsInline = true
  videoInfo.video.autoplay = true
  videoInfo.video.muted = true

  navigator.mediaDevices
    .getUserMedia({
      video: {
        height: {
          ideal: 1620
        },
        width: {
          ideal: 1620
        },
        facingMode: {
          exact: 'environment'
        }
      },
      audio: false
    })
    .then(function (stream) {
      let playing = false
      let timeupdate = false

      videoInfo.video.addEventListener(
        'playing',
        function () {
          playing = true
          checkReady()
        },
        true
      )

      videoInfo.video.addEventListener(
        'timeupdate',
        function () {
          timeupdate = true
          checkReady()
        },
        true
      )

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (videoInfo.video.mozCaptureStream) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        videoInfo.video.mozSrcObject = stream
      } else {
        videoInfo.video.srcObject = stream
      }
      videoInfo.video.play()

      function checkReady() {
        if (playing && timeupdate && !videoInfo.copyVideo) {
          videoInfo.width = videoInfo.video.videoWidth
          videoInfo.height = videoInfo.video.videoHeight
          videoInfo.copyVideo = true
        }
      }
    })
    .catch(function () {
      router.push('/error')
    })

  return videoInfo
}

function setupVideo(url: string) {
  const videoInfo = reactive<VideoInfo>({
    copyVideo: false,
    width: 1,
    height: 1,
    video: document.createElement('video')
  })

  let playing = false
  let timeupdate = false

  videoInfo.video.playsInline = true
  videoInfo.video.autoplay = true
  videoInfo.video.muted = true
  videoInfo.video.loop = true

  // Waiting for these 2 events ensures
  // there is data in the video

  videoInfo.video.addEventListener(
    'playing',
    function () {
      playing = true
      checkReady()
    },
    true
  )

  videoInfo.video.addEventListener(
    'timeupdate',
    function () {
      timeupdate = true
      checkReady()
    },
    true
  )

  videoInfo.video.src = url
  videoInfo.video.play()

  function checkReady() {
    if (playing && timeupdate && !videoInfo.copyVideo) {
      videoInfo.width = videoInfo.video.videoWidth
      videoInfo.height = videoInfo.video.videoHeight
      videoInfo.copyVideo = true
    }
  }

  return videoInfo
}

function createTexture(gl: WebGLRenderingContext) {
  const tex = gl.createTexture()
  gl.bindTexture(gl.TEXTURE_2D, tex)
  // Fill the texture with a 1x1 blue pixel.
  gl.texImage2D(
    gl.TEXTURE_2D,
    0,
    gl.RGBA,
    1,
    1,
    0,
    gl.RGBA,
    gl.UNSIGNED_BYTE,
    new Uint8Array([0, 0, 255, 255])
  )

  // Set the parameters so we can render any size image.
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE)
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE)
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.NEAREST)
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.NEAREST)

  return tex
}

function updateTexture(
  gl: WebGLRenderingContext,
  texture: WebGLTexture | null,
  source: HTMLVideoElement | HTMLImageElement
) {
  gl.bindTexture(gl.TEXTURE_2D, texture)
  gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, gl.RGBA, gl.UNSIGNED_BYTE, source)
}

function getObjectFitSize(
  video: VideoInfo,
  canvas: HTMLCanvasElement | OffscreenCanvas
) {
  const videoRatio = video.width / video.height
  const canvasRatio = canvas.width / canvas.height

  const canvasWider = canvasRatio > videoRatio

  const width = canvasWider ? canvas.width : videoRatio * canvas.height
  const height = canvasWider ? canvas.width / videoRatio : canvas.height
  const dstX = canvasWider ? 0 : (canvas.width - width) / 2
  const dstY = canvasWider ? (canvas.height - height) / 2 : 0

  return {
    width,
    height,
    dstX,
    dstY
  }
}

export default defineComponent({
  name: 'CanvasComponent',
  components: { Loader },
  setup() {
    const { videosLoaded } = useLoader()
    const canvasRef = ref<HTMLCanvasElement | null>(null)

    onMounted(() => {
      const canvas = canvasRef.value

      if (!canvas) return
      const pixelRatio = window.devicePixelRatio || 1

      const gl = canvas.getContext('webgl')
      if (!gl) return

      // setup GLSL program
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const program = webglUtils.createProgramFromScripts(gl, [
        'drawImage-vertex-shader',
        'drawImage-fragment-shader'
      ])

      // look up where the vertex data needs to go.
      const positionLocation = gl.getAttribLocation(program, 'a_position')
      const texcoordLocation = gl.getAttribLocation(program, 'a_texcoord')

      // lookup uniforms
      const matrixLocation = gl.getUniformLocation(program, 'u_matrix')
      const textureLocation = gl.getUniformLocation(program, 'u_texture')
      const replaceTextureLocation = gl.getUniformLocation(
        program,
        'replace_texture'
      )
      const replace2TextureLocation = gl.getUniformLocation(
        program,
        'replace2_texture'
      )

      // Create a buffer.
      const positionBuffer = gl.createBuffer()
      gl.bindBuffer(gl.ARRAY_BUFFER, positionBuffer)

      // Put a unit quad in the buffer
      const positions = [0, 0, 0, 1, 1, 0, 1, 0, 0, 1, 1, 1]
      gl.bufferData(
        gl.ARRAY_BUFFER,
        new Float32Array(positions),
        gl.STATIC_DRAW
      )

      // Create a buffer for texture coords
      const texcoordBuffer = gl.createBuffer()
      gl.bindBuffer(gl.ARRAY_BUFFER, texcoordBuffer)

      // Put texcoords in the buffer
      const texcoords = [0, 0, 0, 1, 1, 0, 1, 0, 0, 1, 1, 1]
      gl.bufferData(
        gl.ARRAY_BUFFER,
        new Float32Array(texcoords),
        gl.STATIC_DRAW
      )

      const videoInfo = setupCamera()
      // const videoInfo = setupVideo('/IMG_1230.mp4')
      const texture = createTexture(gl)

      const repVideoInfo = setupVideo('/rep1.mp4')
      const repTexture = createTexture(gl)

      const rep2VideoInfo = setupVideo('/rep2.mp4')
      const rep2Texture = createTexture(gl)

      function redraw(gl: WebGLRenderingContext) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        webglUtils.resizeCanvasToDisplaySize(gl.canvas, pixelRatio)

        // Tell WebGL how to convert from clip space to pixels
        gl.viewport(0, 0, gl.canvas.width, gl.canvas.height)

        gl.clear(gl.COLOR_BUFFER_BIT)
      }

      // Unlike images, textures do not have a width and height associated
      // with them so we'll pass in the width and height of the texture
      function drawImage(
        tex: WebGLTexture | null,
        texWidth: number,
        texHeight: number,
        dstX: number,
        dstY: number,
        gl: WebGLRenderingContext,
        tex2: WebGLTexture | null,
        tex3: WebGLTexture | null
      ) {
        // Tell WebGL to use our shader program pair
        gl.useProgram(program)

        // set which texture units to render with.
        gl.uniform1i(textureLocation, 0) // texture unit 0
        gl.uniform1i(replaceTextureLocation, 1) // texture unit 1
        gl.uniform1i(replace2TextureLocation, 2) // texture unit 2

        // Set each texture unit to use a particular texture.
        gl.activeTexture(gl.TEXTURE0)
        gl.bindTexture(gl.TEXTURE_2D, tex)
        gl.activeTexture(gl.TEXTURE1)
        gl.bindTexture(gl.TEXTURE_2D, tex2)
        gl.activeTexture(gl.TEXTURE2)
        gl.bindTexture(gl.TEXTURE_2D, tex3)

        // Setup the attributes to pull data from our buffers
        gl.bindBuffer(gl.ARRAY_BUFFER, positionBuffer)
        gl.enableVertexAttribArray(positionLocation)
        gl.vertexAttribPointer(positionLocation, 2, gl.FLOAT, false, 0, 0)
        gl.bindBuffer(gl.ARRAY_BUFFER, texcoordBuffer)
        gl.enableVertexAttribArray(texcoordLocation)
        gl.vertexAttribPointer(texcoordLocation, 2, gl.FLOAT, false, 0, 0)

        // this matrix will convert from pixels to clip space
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        let matrix = m4.orthographic(
          0,
          gl.canvas.width,
          gl.canvas.height,
          0,
          -1,
          1
        )

        // this matrix will translate our quad to dstX, dstY
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        matrix = m4.translate(matrix, dstX, dstY, 0)

        // this matrix will scale our 1 unit quad
        // from 1 unit to texWidth, texHeight units
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        matrix = m4.scale(matrix, texWidth, texHeight, 1)

        // Set the matrix.
        gl.uniformMatrix4fv(matrixLocation, false, matrix)

        // draw the quad (2 triangles, 6 vertices)
        gl.drawArrays(gl.TRIANGLES, 0, 6)
      }

      function render(time: number, gl: WebGLRenderingContext) {
        redraw(gl)
        if (
          videoInfo.copyVideo &&
          repVideoInfo.copyVideo &&
          rep2VideoInfo.copyVideo
        ) {
          if (!videosLoaded.value) videosLoaded.value = true

          updateTexture(gl, texture, videoInfo.video)
          updateTexture(gl, repTexture, repVideoInfo.video)
          updateTexture(gl, rep2Texture, rep2VideoInfo.video)

          const { width, height, dstX, dstY } = getObjectFitSize(
            videoInfo,
            gl.canvas
          )

          drawImage(
            texture,
            width,
            height,
            dstX,
            dstY,
            gl,
            repTexture,
            rep2Texture
          )
        }

        requestAnimationFrame(time => render(time, gl))
      }

      requestAnimationFrame(time => render(time, gl))
    })

    return {
      canvasRef,
      videosLoaded
    }
  }
})
</script>

<style lang="scss" scoped>
.canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>
