
import { defineComponent } from 'vue'
import UseMobile from '@/components/UseMobile.vue'
import { isMobile } from '@/assets/ts/utils'

export default defineComponent({
  components: { UseMobile },
  setup() {
    return { isMobile }
  }
})
