<template>
  <div class="loader">
    <svg
      width="1039"
      height="796"
      viewBox="0 0 1039 796"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class="svg"
      ref="svgRef"
    >
      <path
        d="M982.675 593.446C966.76 619.955 947.625 644.439 925.442 666.639C890.479 701.592 849.808 729.051 804.495 748.211C757.544 768.125 707.727 778.192 656.33 778.166L380.523 778.347C329.205 778.29 279.3 768.227 232.368 748.357C186.998 729.152 146.357 701.742 111.432 666.749C76.5086 631.755 49.0746 591.05 29.9313 545.696C10.0343 498.705 -0.0234002 448.843 0.00132982 397.401C-0.13308 346.024 9.92223 296.075 29.8538 249.07C49.0423 203.661 76.4265 162.983 111.39 128.028C146.353 93.0742 187.024 65.6159 232.337 46.4562C279.288 26.541 329.106 16.4746 380.503 16.5L656.294 16.5112C707.692 16.5359 757.485 26.5525 804.449 46.5C849.819 65.7065 890.461 93.1153 925.385 128.109C960.308 163.102 987.742 203.809 1006.89 249.163C1026.78 296.154 1036.84 346.015 1036.82 397.457C1036.79 448.899 1026.78 498.735 1006.85 545.741C1000.01 562.201 991.849 578.172 982.675 593.446ZM164.076 267.675C140.806 306.409 128.377 350.936 128.366 397.377C128.413 464.792 154.622 528.102 202.257 575.808C249.86 623.433 313.236 649.695 380.513 649.679L656.305 649.69C723.662 649.643 786.916 623.411 834.58 575.734C882.164 528.09 908.402 464.658 908.387 397.323C908.34 329.907 882.131 266.597 834.496 218.892C786.893 171.266 723.517 145.005 656.24 145.02L380.448 145.009C313.091 145.057 249.837 171.288 202.172 218.965C187.38 233.829 174.58 250.198 164.076 267.675Z"
        fill="white"
        id="loader-oval"
      />
      <path
        d="M982.675 593.446C966.76 619.955 947.625 644.439 925.442 666.639C890.479 701.592 849.808 729.051 804.495 748.211C757.544 768.125 707.727 778.192 656.33 778.166L380.523 778.347C329.205 778.29 279.3 768.227 232.368 748.357C186.998 729.152 146.357 701.742 111.432 666.749C76.5086 631.755 49.0746 591.05 29.9313 545.696C10.0343 498.705 -0.0234002 448.843 0.00132982 397.401C-0.13308 346.024 9.92223 296.075 29.8538 249.07C49.0423 203.661 76.4265 162.983 111.39 128.028C146.353 93.0742 187.024 65.6159 232.337 46.4562C279.288 26.541 329.106 16.4746 380.503 16.5L656.294 16.5112C707.692 16.5359 757.485 26.5525 804.449 46.5C849.819 65.7065 890.461 93.1153 925.385 128.109C960.308 163.102 987.742 203.809 1006.89 249.163C1026.78 296.154 1036.84 346.015 1036.82 397.457C1036.79 448.899 1026.78 498.735 1006.85 545.741C1000.01 562.201 991.849 578.172 982.675 593.446ZM164.076 267.675C140.806 306.409 128.377 350.936 128.366 397.377C128.413 464.792 154.622 528.102 202.257 575.808C249.86 623.433 313.236 649.695 380.513 649.679L656.305 649.69C723.662 649.643 786.916 623.411 834.58 575.734C882.164 528.09 908.402 464.658 908.387 397.323C908.34 329.907 882.131 266.597 834.496 218.892C786.893 171.266 723.517 145.005 656.24 145.02L380.448 145.009C313.091 145.057 249.837 171.288 202.172 218.965C187.38 233.829 174.58 250.198 164.076 267.675Z"
        fill="white"
        id="loader-oval-backup"
        class="hidden"
      />
      <path
        d="M187.353 674.315C193.174 684.753 204.197 691.064 216.056 690.898L821.026 690.566C839.31 690.509 854.154 675.741 854.157 657.33C854.155 651.477 852.642 645.856 849.718 640.748L547.19 117.142C538.026 101.322 517.631 95.8687 501.845 105.126C496.831 108.015 492.706 112.177 489.836 117.159L187.429 641.191C181.221 651.326 181.19 664.107 187.353 674.315ZM100.403 724.585C76.2469 683.361 76.2745 632.27 100.33 591.057L402.831 66.9911C439.49 3.10004 520.98 -18.898 584.837 17.6668C605.311 29.4765 622.318 46.3791 634.046 66.9059L936.609 590.606C973.621 654.287 952.03 735.893 888.348 772.906C867.89 784.822 844.713 791.022 821.082 791.004L216.077 791.242C168.319 791.598 124.124 766.074 100.403 724.585Z"
        fill="white"
        id="loader-triangle"
        class="hidden"
      />
    </svg>

    <p class="text">Jeszcze chwila...</p>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from 'vue'
import gsap, { TimelineMax, Power2, Linear } from 'gsap'
import MorphSVGPlugin from 'gsap/MorphSVGPlugin'
import CSSPlugin from 'gsap/CSSPlugin'

gsap.registerPlugin(MorphSVGPlugin)
gsap.registerPlugin(CSSPlugin)

export default defineComponent({
  setup() {
    const svgRef = ref(null)

    onMounted(() => {
      const svg = svgRef.value

      const ovalAnim = new TimelineMax({ paused: true })
        .to(svg, { rotate: '720deg', duration: 1, ease: Linear.easeNone })
        .to(
          '#loader-oval',
          {
            morphSVG: '#loader-triangle',
            duration: 0.3,
            ease: Linear.easeNone
          },
          '-=0.3'
        )

      const triangleAnim = new TimelineMax({ paused: true })
        .to(svg, { rotate: '720deg', duration: 1, ease: Linear.easeNone })
        .to(
          '#loader-oval',
          {
            morphSVG: '#loader-oval-backup',
            duration: 0.3,
            ease: Linear.easeNone
          },
          '-=0.3'
        )

      new TimelineMax({ repeat: -1 })
        .to(
          ovalAnim,
          {
            progress: 1,
            duration: ovalAnim.duration(),
            ease: Power2.easeInOut
          },
          '+=1'
        )
        .set(svg, { rotate: '0deg', transformOrigin: '50% 60%' })
        .to(
          triangleAnim,
          {
            progress: 1,
            duration: triangleAnim.duration(),
            ease: Power2.easeInOut
          },
          '+=1'
        )
    })

    return {
      svgRef
    }
  }
})
</script>

<style lang="scss" scoped>
.loader {
  @include center-content;
  flex-direction: column;

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.svg {
  width: 16vw;
  height: 12.25vw;

  @include media-up(lg) {
    width: 90px;
    height: 69px;
  }
}

.text {
  margin-top: 20px;
  text-transform: uppercase;
  letter-spacing: -0.3px;
}

.hidden {
  opacity: 0;
}
</style>
