<template>
  <div class="wrapper">
    <div class="content">
      <img class="qr" src="qr.svg" alt="" />
      <p class="text t-m-text">Wejdź na ar.ktr.org.pl w wersji mobile</p>
      <p class="text t-m-text">
        Skieruj telefon na niebieski element na naszej stronie lub w Twoim
        otoczeniu i zobacz więcej!
      </p>
      <img class="figure img" src="/instrukcja_ilustracja.svg" alt="" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({})
</script>

<style lang="scss" scoped>
.wrapper {
  @include fill-space($position: fixed);
  @include center-content;
}

.content {
  position: relative;
  max-width: 80%;
}

.icon {
  --icon-width: 20px;
  position: absolute;
  left: 0;
  top: 0;

  @include media-down(xxl) {
    --icon-width: 10px;
  }
}

.text {
  color: white;
  margin-top: 10px;
}

.qr {
  display: block;
  margin-bottom: 28px;
}

.figure {
  display: block;
  width: 222px;
  margin: 42px auto 0;
}
</style>
