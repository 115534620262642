
import { defineComponent, onMounted, ref } from 'vue'
import gsap, { TimelineMax, Power2, Linear } from 'gsap'
import MorphSVGPlugin from 'gsap/MorphSVGPlugin'
import CSSPlugin from 'gsap/CSSPlugin'

gsap.registerPlugin(MorphSVGPlugin)
gsap.registerPlugin(CSSPlugin)

export default defineComponent({
  setup() {
    const svgRef = ref(null)

    onMounted(() => {
      const svg = svgRef.value

      const ovalAnim = new TimelineMax({ paused: true })
        .to(svg, { rotate: '720deg', duration: 1, ease: Linear.easeNone })
        .to(
          '#loader-oval',
          {
            morphSVG: '#loader-triangle',
            duration: 0.3,
            ease: Linear.easeNone
          },
          '-=0.3'
        )

      const triangleAnim = new TimelineMax({ paused: true })
        .to(svg, { rotate: '720deg', duration: 1, ease: Linear.easeNone })
        .to(
          '#loader-oval',
          {
            morphSVG: '#loader-oval-backup',
            duration: 0.3,
            ease: Linear.easeNone
          },
          '-=0.3'
        )

      new TimelineMax({ repeat: -1 })
        .to(
          ovalAnim,
          {
            progress: 1,
            duration: ovalAnim.duration(),
            ease: Power2.easeInOut
          },
          '+=1'
        )
        .set(svg, { rotate: '0deg', transformOrigin: '50% 60%' })
        .to(
          triangleAnim,
          {
            progress: 1,
            duration: triangleAnim.duration(),
            ease: Power2.easeInOut
          },
          '+=1'
        )
    })

    return {
      svgRef
    }
  }
})
