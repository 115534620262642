
import { defineComponent } from 'vue'
import { useLoader } from '@/composite/videoLoading'
import UseMobile from '@/components/UseMobile.vue'
import { isMobile } from '@/assets/ts/utils'
import StartScreen from '@/components/StartScreen.vue'
import CanvasComponent from '@/components/CanvasComponent.vue'

export default defineComponent({
  name: 'Home',
  components: {
    StartScreen,
    UseMobile,
    CanvasComponent
  },
  setup() {
    const { loaderStarted: cameraEnabled } = useLoader()
    return { isMobile, cameraEnabled }
  }
})
