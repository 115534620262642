<template>
  <div class="start">
    <p>
      Skieruj aparat telefonu na kolor w&nbsp;naszej komunikacji i&nbsp;zobacz
      więcej niż&nbsp;inni.<br /><br />
      <img src="arrow-down.svg" alt="" />
    </p>

    <button @click="enableCamera" class="button">Włącz aparat</button>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  setup(_, ctx) {
    function enableCamera() {
      ctx.emit('enableCamera')
    }

    return { enableCamera }
  }
})
</script>

<style lang="scss" scoped>
.start {
  position: fixed;
  top: 100px;
  bottom: 63px;
  left: 20px;
  right: 20px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  text-transform: uppercase;
  font-weight: $regular;
  font-size: 30px;
  line-height: 32px;
  letter-spacing: -0.3px;
}

.button {
  text-decoration: underline;
  text-transform: uppercase;
  text-align: left;
}
</style>
