<template>
  <div ref="el" class="animation">
    <img src="/oval.svg" alt="" ref="oval" class="figure" />
    <img
      src="/triangle.svg"
      alt=""
      ref="triangle"
      class="figure"
      style="transform: translateX(100%)"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from 'vue'
import { useLoader } from '@/composite/videoLoading'
import gsap, { TimelineMax, Linear, Power2 } from 'gsap'
import CSSPlugin from 'gsap/CSSPlugin'

gsap.registerPlugin(CSSPlugin)

function getObjectFitSize(
  contains: boolean /* true = contain, false = cover */,
  containerWidth: number,
  containerHeight: number,
  width: number,
  height: number
) {
  const doRatio = width / height
  const cRatio = containerWidth / containerHeight
  const test = contains ? doRatio > cRatio : doRatio < cRatio
  const targetWidth = test ? containerWidth : containerHeight * doRatio
  const targetHeight = test ? containerWidth / doRatio : containerHeight

  return {
    width: targetWidth,
    height: targetHeight,
    x: (containerWidth - targetWidth) / 2,
    y: (containerHeight - targetHeight) / 2
  }
}

function animate(
  el: HTMLElement,
  oval: HTMLImageElement,
  triangle: HTMLImageElement
) {
  const { loaderStarted } = useLoader()

  const ovalSize = getObjectFitSize(
    true,
    oval.clientWidth,
    oval.clientHeight,
    892,
    1215
  )
  const triangleSize = getObjectFitSize(
    true,
    triangle.clientWidth,
    triangle.clientHeight,
    873,
    796
  )

  const triangleAnimation = new TimelineMax({ paused: true })
    .to(
      triangle,
      {
        x: '-15%',
        y: '-10%',
        rotate: '-62deg',
        duration: 1.5,
        ease: Linear.easeNone
      },
      '+=2'
    )
    .to(triangle, {
      rotate: '-149deg',
      x: '235%',
      y: '-15%',
      scale: window.innerWidth / (triangleSize.width / 9.5),
      duration: 4,
      ease: Linear.easeNone
    })

  const ovalAnimation = new TimelineMax({ paused: true })
    .to(oval, {
      x: 0,
      y: '-15%',
      rotate: '-90deg',
      ease: Linear.easeNone,
      duration: 2
    })
    .to(oval, {
      rotate: '-180deg',
      y: 0,
      scale: Math.max(
        window.innerWidth / (ovalSize.width * 0.95),
        window.innerHeight / (ovalSize.height * 0.65)
      ),
      ease: Linear.easeNone,
      duration: 1
    })

  const tl = new TimelineMax({ repeat: -1 })
    .set(el, { background: '#0000ff' })
    .set(oval, {
      zIndex: 0,
      scale: Math.max(
        window.innerWidth / (ovalSize.width * 0.95),
        window.innerHeight / (ovalSize.height * 0.45)
      )
    })
    .set(triangle, { x: '100%', y: '20%', scale: 1, rotate: '0deg' })
    .add(() => {
      if (loaderStarted.value) tl.kill()
    })
    .to(
      triangleAnimation,
      {
        progress: 1,
        ease: Power2.easeIn,
        duration: triangleAnimation.duration()
      },
      '-=2'
    )
    .set(el, { background: '#ff3522' })
    .set(triangle, { zIndex: 0 })
    .set(oval, { x: '-125%', y: '15%', scale: 1, rotate: '90deg', zIndex: 1 })
    .add(() => {
      if (loaderStarted.value) tl.kill()
    })
    .to(
      ovalAnimation,
      {
        progress: 1,
        duration: ovalAnimation.duration()
      },
      '+=4'
    )
}

export default defineComponent({
  name: 'AnimationBg',
  setup() {
    const el = ref<HTMLDivElement | null>(null)
    const oval = ref<HTMLImageElement | null>(null)
    const triangle = ref<HTMLImageElement | null>(null)

    onMounted(() => {
      if (el.value && oval.value && triangle.value) {
        animate(el.value, oval.value, triangle.value)
      }
    })

    return {
      el,
      oval,
      triangle
    }
  }
})
</script>

<style lang="scss" scoped>
.animation {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: $blue;
}

.figure {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
</style>
