<template>
  <div class="home-wrap">
    <transition name="fade" mode="out-in">
      <UseMobile v-if="!isMobile" />
      <StartScreen
        v-else-if="!cameraEnabled"
        @enable-camera="cameraEnabled = true"
      />
      <CanvasComponent v-else />
    </transition>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useLoader } from '@/composite/videoLoading'
import UseMobile from '@/components/UseMobile.vue'
import { isMobile } from '@/assets/ts/utils'
import StartScreen from '@/components/StartScreen.vue'
import CanvasComponent from '@/components/CanvasComponent.vue'

export default defineComponent({
  name: 'Home',
  components: {
    StartScreen,
    UseMobile,
    CanvasComponent
  },
  setup() {
    const { loaderStarted: cameraEnabled } = useLoader()
    return { isMobile, cameraEnabled }
  }
})
</script>

<style lang="scss" scoped>
.home-wrap {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
</style>
