<template>
  <div>
    <AnimationBg />
    <Menu v-model="menuOpened" />
    <router-view
      class="router-view"
      :class="{ 'router-view--menu-opened': menuOpened }"
    />
  </div>
</template>

<script>
import AnimationBg from '@/components/AnimationBg'
import Menu from '@/components/Menu'
import { ref, defineComponent } from 'vue'

export default defineComponent({
  components: { Menu, AnimationBg },
  setup() {
    const menuOpened = ref(false)
    return { menuOpened }
  }
})
</script>

<style lang="scss">
@import '~ress';
@import './assets/scss/main.scss';

.router-view {
  position: relative;
  z-index: 1;

  clip-path: polygon(0 58px, 100% 58px, 100% 100%, 0% 100%);
  transition: all 0.3s;

  &--menu-opened {
    clip-path: polygon(0 138px, 100% 138px, 100% 100%, 0% 100%);
  }
}
</style>
