<template>
  <div class="nav-wrap" id="nav">
    <div class="navbar">
      <a class="logo-link" href="/">
        <img
          class="logo"
          srcset="/logo-ktr.png 43w, /logo-ktr@2x.png 86w"
          sizes="(min-width: 1920px) 43px,
                 (min-width: 1280px) 26px, 20px"
          alt=""
        />
      </a>

      <button class="menu-btn" @click="toggle">Menu</button>
    </div>

    <ul class="menu" :class="{ 'menu--opened': modelValue }">
      <li><a href="https://ktr.org.pl/">Wróć do KTR</a></li>
      <li><a href="https://innovation.sar.org.pl/">Wróć do Innovation</a></li>
    </ul>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    modelValue: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    toggle() {
      this.$emit('update:modelValue', !this.modelValue)
    }
  }
})
</script>

<style lang="scss" scoped>
.nav-wrap {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
}

.navbar {
  position: relative;
  z-index: 1;

  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 58px;
  padding: 0 20px;

  border-bottom: 1px solid $white;
}

.logo {
  width: 20px;
}

.menu-btn {
  text-transform: uppercase;
}

.menu {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;

  list-style: none;

  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 77px 20px 12px 14px;
  border-bottom: 1px solid $white;

  transform: translateY(-80px);
  clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0 100%);
  transition: all 0.3s;

  &--opened {
    transform: translateY(0);
    clip-path: polygon(0 58px, 100% 58px, 100% 100%, 0 100%);
  }
}

.menu a {
  color: $white;
  text-decoration: none;
  text-transform: uppercase;
  line-height: 1.5;
  letter-spacing: 1.5px;
}
</style>
