import { ref } from 'vue'
const videosLoaded = ref(false)
const loaderStarted = ref(false)

export function useLoader(): {
  videosLoaded: typeof videosLoaded
  loaderStarted: typeof loaderStarted
} {
  return { videosLoaded, loaderStarted }
}
